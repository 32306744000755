import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dns"
    }}>{`DNS`}</h1>
    <p>{`For users who want the flexibility to embed custom DNS tokens within their infrastructure, we make them available.`}</p>
    <p><strong parentName="p">{`WE WANT TO HEAR FROM YOU!!!`}</strong>{` If you've created unique ways to embed tokens, please let us know! We would love to hear how our users are creatively deploying Dragnet, and we might be able to incorporate your methods into Dragnet!`}</p>
    <h3 {...{
      "id": "limitations"
    }}>{`Limitations`}</h3>
    <p>{`Windows Folder and DNS tokens can take a bit more time than HTTP tokens to trigger on our server.  Most of the time it will not take more than 90 seconds to trigger the event and send notifications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      